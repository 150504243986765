export interface BillingModelImpl {
  amount: number;
  country: string;
  payments: number;
  chargebacks: number;
  chargebacksAmount: number;
  refunds: number;
  refundsAmount: number;
  amount_real: number;
  taxes: number;
  vatApplied: number;
  netProfit: number;
}

export class BillingModelDTO implements BillingModelImpl {
  amount!: number;
  country!: string;
  payments!: number;
  chargebacks!: number;
  chargebacksAmount!: number;
  refunds!: number;
  refundsAmount!: number;
  amount_real!: number;
  taxes!: number;
  vatApplied!: number;
  netProfit!: number;
}

export default class BillingModel extends BillingModelDTO {
  constructor(dto: Partial<BillingModelDTO>) {
    super();
    Object.assign(this, dto);
  }

  generateForExcel = () => {
    return {
      Country: this.country,
      Payments: this.payments,
      Amount: (this.amount + '').replace('.', ','),
      chargebacks: this.chargebacks,
      chargebacksAmount: ('-' + this.chargebacksAmount).replace('.', ','),
      refunds: this.refunds,
      refundsAmount: ('-' + this.refundsAmount).replace('.', ','),
      amount_real: (this.amount_real + '').replace('.', ','),
      taxes: (this.taxes + '').replace('.', ','),
      vatApplied: this.vatApplied,
      netProfit: (this.netProfit + '').replace('.', ','),
    };
  };
}
