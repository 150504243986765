import API from '../repository';
import BillingModel, { BillingModelDTO } from '@/models/Billing.model';

const RESOURCE = '/billing';

export default {
  async getBilling(month: number, year: number) {
    const result: BillingModelDTO[] = await API.get(RESOURCE, {
      params: {
        month,
        year,
      },
    });
    return result.map(r => new BillingModel(r));
  },
};
