var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h1',{staticClass:"text-md-h2 mb-10"},[_vm._v("Billing")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('BaseCardFilters',[_c('v-row',{staticClass:"align-center mb-n4 mt-n2"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.optionsMonths,"label":"Select Month","prepend-inner-icon":"mdi-calendar"},model:{value:(_vm.monthSelected),callback:function ($$v) {_vm.monthSelected=$$v},expression:"monthSelected"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.optionsYears,"label":"Select Year","prepend-inner-icon":"mdi-calendar"},model:{value:(_vm.yearSelected),callback:function ($$v) {_vm.yearSelected=$$v},expression:"yearSelected"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"flex-grow-1",attrs:{"small":"","dark":"","color":"teal darken-2"},on:{"click":function($event){return _vm.loadData()}}},[_vm._v("Search")]),_c('v-btn',{staticClass:"ml-2 flex-grow-1",attrs:{"small":"","dark":"","color":"teal darken-2"},on:{"click":function($event){return _vm.generateXlsx()}}},[_vm._v("Download Billing")]),_c('v-btn',{staticClass:"ml-2 flex-grow-1",attrs:{"small":"","dark":"","color":"teal darken-2"},on:{"click":function($event){return _vm.downloadInvoices()}}},[_vm._v("Download Invoices")])],1)])],1)],1),_c('BaseCardFilters',{attrs:{"label":"Totals:"}},[_c('v-data-table',{attrs:{"dense":"","hide-default-footer":"","headers":_vm.headersTable,"items":_vm.totalsData},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.amount.toFixed(2))+"€")])]}},{key:"item.chargebacksAmount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("-"+_vm._s(item.chargebacksAmount.toFixed(2))+"€")])]}},{key:"item.refundsAmount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("-"+_vm._s(item.refundsAmount.toFixed(2))+"€")])]}},{key:"item.taxes",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.taxes.toFixed(2))+"€")])]}},{key:"item.netProfit",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.netProfit.toFixed(2))+"€")])]}}])})],1),_c('BaseCardFilters',{attrs:{"label":"Totals by countries:"}},[_c('v-data-table',{attrs:{"dense":"","hide-default-footer":"","headers":_vm.headersTableCountry,"items":_vm.dataByCountry},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.amount)+"€")])]}},{key:"item.chargebacksAmount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("-"+_vm._s(item.chargebacksAmount)+"€")])]}},{key:"item.refundsAmount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("-"+_vm._s(item.refundsAmount)+"€")])]}},{key:"item.taxes",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.taxes)+"€")])]}},{key:"item.netProfit",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.netProfit)+"€")])]}},{key:"item.vatApplied",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.vatApplied)+"%")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }