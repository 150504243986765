






















































import { Component, Vue } from 'vue-property-decorator';
import billingRepository from '@/repository/modules/billing.repository';
import BillingModel from '@/models/Billing.model';

import XLSX from 'xlsx';
import invoicesRepository from '@/repository/modules/invoices.repository';

@Component
export default class Billing extends Vue {
  optionsYears = [
    {
      text: '2021',
      value: 2021,
    },
    {
      text: '2022',
      value: 2022,
    },
    {
      text: '2023',
      value: 2023,
    },
    {
      text: '2024',
      value: 2024,
    },
    {
      text: '2025',
      value: 2025,
    },
  ];

  optionsMonths = [
    {
      text: 'January',
      value: 0,
    },
    {
      text: 'February',
      value: 1,
    },
    {
      text: 'March',
      value: 2,
    },
    {
      text: 'April',
      value: 3,
    },
    {
      text: 'May',
      value: 4,
    },
    {
      text: 'June',
      value: 5,
    },
    {
      text: 'July',
      value: 6,
    },
    {
      text: 'August',
      value: 7,
    },
    {
      text: 'September',
      value: 8,
    },
    {
      text: 'October',
      value: 9,
    },
    {
      text: 'November',
      value: 10,
    },
    {
      text: 'December',
      value: 11,
    },
  ];

  sharedHeader = [
    {
      text: 'Payments',
      align: 'start',
      value: 'payments',
    },
    {
      text: 'Amount',
      align: 'start',
      value: 'amount',
    },
    {
      text: 'CB',
      align: 'start',
      value: 'chargebacks',
    },
    {
      text: 'A.CB',
      align: 'start',
      value: 'chargebacksAmount',
    },
    {
      text: 'Refunds',
      align: 'start',
      value: 'refunds',
    },
    {
      text: 'A.Refunds',
      align: 'start',
      value: 'refundsAmount',
    },
    {
      text: 'Taxes',
      align: 'start',
      value: 'taxes',
    },
  ];

  headersTable = [
    ...this.sharedHeader,
    {
      text: 'Net Profit',
      align: 'end',
      value: 'netProfit',
    },
  ];

  headersTableCountry = [
    {
      text: 'Country',
      align: 'start',
      value: 'country',
    },
    ...this.sharedHeader,
    {
      text: 'Tax Aplied',
      align: 'start',
      value: 'vatApplied',
    },
    {
      text: 'Net Profit',
      align: 'end',
      value: 'netProfit',
    },
  ];

  actualMonth = new Date().getMonth();
  actualYear = new Date().getFullYear();
  monthSelected = this.actualMonth;
  yearSelected = this.actualYear;

  dataByCountry: BillingModel[] = [];

  created() {
    this.loadData();
  }

  async loadData() {
    this.dataByCountry = await billingRepository.getBilling(this.monthSelected, this.yearSelected);
  }

  get totalsData() {
    const dataBilling = new BillingModel({
      amount: 0,
      payments: 0,
      chargebacks: 0,
      chargebacksAmount: 0,
      refunds: 0,
      refundsAmount: 0,
      amount_real: 0,
      taxes: 0,
      netProfit: 0,
    });

    this.dataByCountry.forEach((data) => {
      dataBilling.amount += data.amount;
      dataBilling.payments += data.payments;
      dataBilling.chargebacks += data.chargebacks;
      dataBilling.chargebacksAmount += data.chargebacksAmount;
      dataBilling.refunds += data.refunds;
      dataBilling.refundsAmount += data.refundsAmount;
      dataBilling.amount_real += data.amount_real;
      dataBilling.taxes += data.taxes;
      dataBilling.netProfit += data.netProfit;
    });

    return [dataBilling];
  }

  async generateXlsx() {
    const dataFormatedForExcel = XLSX.utils.json_to_sheet(this.dataByCountry.map((item) => item.generateForExcel()));
    const wb = XLSX.utils.book_new(); // creamos un nuevo "Libro" de excel
    // añadiremos una hoja a nuestro nuevo libro
    // un libro puede contener mas de uabn hoja
    // wb : Libro
    // dataFormatedForExcel: la informacion en formato Object json
    // nombre del fichero resultante
    XLSX.utils.book_append_sheet(wb, dataFormatedForExcel, `${this.yearSelected} ${this.monthSelected}`);
    // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons'); //De este modo añadiriamos una hoja dos  llamada pokemons
    // Direct Download
    XLSX.writeFile(wb, `${this.yearSelected}_${this.monthSelected}.xlsx`);
  }

  async downloadInvoices() {
    await invoicesRepository.downloadAllInvoicesAndClients(this.monthSelected, this.yearSelected);
  }
}
